<script setup lang="ts">
    import HTML from 'html-parse-stringify';
    import type { Node as ParsedNode } from 'html-parse-stringify';
    import { levelMap } from '~/components/atm/Heading.vue';
    import { AtmHeading, NuxtLink } from '#components';

    export type HTMLType = {
        type: string | Component;
        props?: Record<string, any>;
        children?: string | HTMLType[];
    };

    interface HTMLProps {
        content: string;
    }

    const props = defineProps<HTMLProps>();

    const parsedContent = computed(() => {
        try {
            if (!props.content) return [];
            if (!/<[a-z][\s\S]*>/i.test(props.content)) {
                return [{ type: 'text', children: decodeHtml(props.content) }];
            }

            const parsed = HTML.parse(props.content);

            const traverse = (nodes: ParsedNode[]): HTMLType[] => {
                const data: HTMLType[] = [];
                if (!nodes) return data;

                nodes.forEach((node) => {
                    if (node.type === 'text') {
                        if (node.content.length > 0) {
                            data.push({ type: 'text', children: decodeHtml(node.content) });
                        }
                    } else if (node.type === 'tag') {
                        const tagName = node.name.toLowerCase();
                        const props: Record<string, any> = node.attrs || {};

                        const children = traverse(node.children);

                        if (tagName !== 'span' && Object.keys(levelMap).includes(tagName)) {
                            data.push({
                                type: AtmHeading,
                                props: { ...props, level: tagName },
                                children,
                            });
                        } else if (tagName === 'a') {
                            const toUrl = props.href || '';
                            data.push({
                                type: NuxtLink,
                                props: { ...props, to: toUrl, href: undefined },
                                children,
                            });
                        } else {
                            data.push({ type: tagName, props, children });
                        }
                    }
                });

                return data;
            };
            if (!parsed) return [];
            return traverse(parsed);
        } catch (error) {
            console.error('Error processing props.content:', { error });
            return [];
        }
    });
</script>
<template>
    <renderer-html-tree :components="parsedContent" />
</template>
